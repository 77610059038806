export class valueList {
    id: number;
    name: string;
}

export class CustomFieldModel {
    id: number;
    labelName: string;
    required = true;
    valueType: string;
    fieldName: string;
    valueList: string;

    //angular use only
    selectedValueList: valueList;
    fieldList: Array<string>;
    value: string;
    requiredString: string;
}
