import { ProductModal } from '../../product/modal/product-modal';
import { SalesOrderModal } from '../../sales-order/modal/sales-order-modal';
import { PartyModal } from '../../party/modal/party-modal';
import { Puritymodal } from '../../purity/modal/puritymodal';
import { MaterialPropertyModel } from '../../../shared/model/material-property-model';
import { MachineModal } from '../../machine/modal/machine-modal';
import { GenricDataModel } from '../../report/modal/genric-data-model';
import { DailyProductionMachineWiseInputModel } from './daily-production-machine-wise-input-model';
import { PHModal } from '../../ph/modal/phmodal';
import { MoistureModal } from '../../moisture/modal/moisture-modal';
import { SolubilityModal } from '../../solubility/modal/solubility-modal';
import { ItemProperties } from '../../daily-production/daily-production-add-update/daily-production-add-update.component';

export class DailyProductionMachineWiseOutputModel extends MaterialPropertyModel {
  id: number;
  machine: MachineModal;
  targetProduction: number;
  shift: ShiftModel;
  item: ProductModal;
  costPerKg: number;
  salesOrder: SalesOrderModal;
  label: ProductModal;
  party: PartyModal;
  cavity: number;
  shiftHours: number;
  weight: number; //actualProd.(Number)
  secondaryQuantity; //finishProd.(Kg)
  cycleTime: number;
  perPieceWeight: number; //weight(gm/piece)
  plainProductRejection: number;
  labelProductRejection: number;
  rejectedPieces: number; //plainProdRejection + labelProdRejection
  dailyProductionBreakDownDetails: Array<BreakDownModel>;
  dailyProductionInputDetails: Array<DailyProductionMachineWiseInputModel>;
  itemProperties = new Array<ItemProperties>();
  purity: Puritymodal;
  ph: PHModal;
  moisture: MoistureModal;
  solubility: SolubilityModal;

  /*Angular use*/
  purityList: Array<Puritymodal>;
  customerList: Array<PartyModal>;
  salesOrderList: Array<SalesOrderModal>;
  salesOrderFilterList: Array<SalesOrderModal>;
  labelList: Array<ProductModal>;
  labelFilterList: Array<ProductModal>;
  isSelected: boolean;
  selectInput: boolean;
  selectBreakDown: boolean;
  productList: Array<ProductModal>;
  inputTotalItem1Qty: number;
  inputTotalItem2Qty: number;
  totalInputRequired: number;
  totalBreakDownTime: number;
}

export const ShiftConst = [
  {
    id: 1,
    shiftName: 'A',
    shiftHours: 8,
  },
  {
    id: 2,
    shiftName: 'B',
    shiftHours: 8,
  },
  {
    id: 3,
    shiftName: 'C',
    shiftHours: 8,
  },
];
export const reasonConst = [
  {
    id: 1,
    name: 'Die set',
  },
  {
    id: 2,
    name: 'No power',
  },
  {
    id: 3,
    name: 'Hydraulic not work',
  },
];

export class BreakDownModel {
  id: number;
  breakDownTime: number;
  reason: Array<GenricDataModel>;
  remark: string;
}

export class ShiftModel {
  id: number;
  shiftName: string;
  shiftHours: number;
}
