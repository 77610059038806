export class MultiRollNoEntryDetailsModel {
  rollNo: string;
  customItemNumber: string;
  loomNo: string;
  grossWeight: number;
  tareWeight: number;
  meter: number;

  rollNoError: boolean = false;
  loomNoError: boolean = false;
  grossWeightError: boolean = false;
  tareWeightError: boolean = false;
  meterError: boolean = false;
}
