import {UomModal} from "../../../core/modal/uom-modal";

export class SupportingMaterial {
    id: number;
    description: string;
    name: string;
    physicalProperty: string;
    productCode: string;
    uom: UomModal;

    //For Angular Use
    uomString: string;
}
