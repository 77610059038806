import { ModuleParamGlobConfigService } from '../../../core/service/moduleFieldConfig/module-param-glob-config.service';

export class quotationModuleParamConfigModel {
  constructor(private moduleParamConfigGlob: ModuleParamGlobConfigService) {}

  paramData = {
    title: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'title', 'Quotation'),
    },
    project_name: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'project_name', 'Project Name'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'project_name'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'project_name'),
    },
    date: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'date', 'Date'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'date'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'date'),
    },
    quotation_number: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'quotation_number', 'Quotation Number'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'quotation_number'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'quotation_number'),
    },
    customer_name: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'customer_name', 'Customer Name'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'customer_name'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'customer_name'),
    },
    customer_representative: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'customer_representative', 'Customer Representative'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'customer_representative'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'customer_representative'),
    },
    staff_name: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'staff_name', 'Staff Name'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'staff_name'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'staff_name'),
    },
    contactPerson: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'contactPerson', 'Contact Person'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'contactPerson'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'contactPerson'),
    },
    segment: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'segment', 'Segment'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'segment'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'segment'),
    },
    shippingAddress: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'shippingAddress', 'Shipping Address'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'shippingAddress'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotation', 'shippingAddress'),
    },
    terms_of_delivery: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'terms_of_delivery', 'Terms Of Delivery'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'terms_of_delivery'),
    },
    terms_of_for: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'terms_of_for', 'Terms Of For'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'terms_of_for'),
    },
    terms_of_tax: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'terms_of_tax', 'Terms Of Tax'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'terms_of_tax'),
    },
    terms_of_other: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'terms_of_other', 'Other T & C'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'terms_of_other'),
    },
    validity: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'validity', 'Validity'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'validity'),
    },
    terms_of_payment: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'terms_of_payment', 'Terms Of Payment'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'terms_of_payment'),
    },
    total_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'totalAmount', 'Total Amount'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'totalAmount'),
    },
    discounted_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'discounted_amount', 'Discounted Amount'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'discounted_amount'),
    },
    final_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotation', 'final_amount', 'Final Amount'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotation', 'final_amount'),
    },
  };

  quotationProductDetails = {
    material: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'material', 'Material'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'material'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotationProductDetails', 'material'),
    },
    purity: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'purity', 'Purity'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'purity'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotationProductDetails', 'purity'),
    },
    final_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'final_amount', 'Final Amount'),
    },
    weight: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'weight', 'Weight'),
    },
    grade: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'grade', 'Grade'),
    },
    quantity: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'quantity', 'Quantity'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'quantity'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotationProductDetails', 'quantity'),
    },
    material_price: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'material_price', 'Material Price'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'purity'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotationProductDetails', 'purity'),
    },
    total_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'totalAmount', 'Total Amount'),
    },
    discounted_amount: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'discounted_amount', 'Discounted Amount'),
    },
    color: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'color', 'Color'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'color'),
    },
    size: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'size', 'Size'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'size'),
    },
    discount: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'discount', 'Discount'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'discount'),
    },
    filler: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'filler', 'Filler'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'filler'),
    },
    gst: {
      label: this.moduleParamConfigGlob.getLabel('quotationProductDetails', 'gst', 'GST'),
      isVisible: this.moduleParamConfigGlob.isVisible('quotationProductDetails', 'gst'),
      isRequired: this.moduleParamConfigGlob.isRequired('quotationProductDetails', 'gst'),
    },
  };
}
