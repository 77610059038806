import { BaseComponent } from "./base-component";
import { IBaseService } from "../service/ibase-service";
import { PaginationData } from "../modal/pagination-data";
import { OrderingData } from "../modal/ordering-data";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ResponseCode } from "../constant/response-code";

export abstract class AbstractBaseComponent<T> implements BaseComponent<T>{
    
    constructor(private toastr : ToastrService,private routerSrv : Router){
    }
    abstract getService():IBaseService<T>;
    abstract setData(T);
    abstract setList(Array);
    abstract getModuleHomeUrl();

    save(modal : T){
        this.getService().save(modal).subscribe(
            (response) =>{
                if(response.responseCode != ResponseCode.OK){
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setData(response.data);
                this.onSaveSuccess();
            }
        );
    }


    update(modal : T){
        this.getService().update(modal).subscribe(
            (response) =>{
                if(response.responseCode != ResponseCode.OK){
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setData(response.data);
                this.onSaveSuccess();
            }
        );
    }
    delete(modal : T){
        this.getService().delete(modal).subscribe(
            (response) =>{
                if(response.responseCode != ResponseCode.OK){
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setData(response.data);
                this.onDeleteSuccess();
            }
        );
    }

    getData(id : number){
        this.getService().getById(id).subscribe(
            (response)=>{
                if(response.responseCode != ResponseCode.OK){
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setData(response.data);
            }
        );
    }

    getList(paginationData : PaginationData,orderingData : OrderingData){
        this.getService().getAll(paginationData,orderingData).subscribe(
            (response) =>{
                if(response.responseCode != ResponseCode.OK){
                    this.onResponseError(response.responseMessage);
                    return;
                }
                this.setList(response.dataList);
            }
        );
    }

    public onSaveSuccess(){
        this.toastr.success("Data has been save successfully.","Success");
        this.goToModuleHome();
    };
    public onUpdateSuccess(){
        this.toastr.success("Data has been updated successfully.","Success");
        this.goToModuleHome();
    };
    public onDeleteSuccess(){
        this.toastr.success("Data has been deleted successfully.","Success");
        this.onDeleteSuccessed();
    };

    public onDeleteSuccessed(){
    }

    private onResponseError(message : string){
        this.toastr.error(message,"Error");
    }

    public goToModuleHome(){
        this.routerSrv.navigate([this.getModuleHomeUrl()]);
    }
}
