import { MachineModal } from '../../machine/modal/machine-modal';
import { Mould } from '../../mould/modal/mould';
import { ProductModal } from '../../product/modal/product-modal';
import { StoreInwardStockModal } from './store-inward-stock-modal';

export class StoreInwardStockByName {
  public item: ProductModal;
  public product: ProductModal;
  public dataList: Array<StoreInwardStockModal>;
  public mouldDataList: Array<StoreInwardStockModal>;
  public isSelected: boolean = false;
  public total: number;
  public totalBlockStock: number;
  public totalSecondaryStock: number;
  public typeList: string;
  public stockId: number;
  public quantity: number;
  public secondaryQuantity: number;
  public notes: string;
  public mould: Mould;
  public machine: MachineModal;
  public lotNumber: string;
  public mouldWiseList: Array<StoreInwardStockModal>;
  public stock: number;
}
