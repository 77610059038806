import { ProcessModel } from '../../process/model/process.model';
import { ProductionBatchDetailsCostHeadModel } from './production-batch-details-cost-head.model';
import { ProductionBatchDetailsInputModel } from './production-batch-details-input.model';
import { ProductionBatchDetailsOutputModel } from './production-batch-details-output.model';
import { PartyModal } from '../../party/modal/party-modal';
import { SalesOrderModal } from '../../sales-order/modal/sales-order-modal';
import { MachineModal } from '../../machine/modal/machine-modal';
import { ProductionBatchDetailsOtherProduct } from './production-batch-details-other-product';
import { DynamicDataModel } from '../../../shared/dynamic-form/model/dynamic-data-model';
import { ProductionBatchPlanningResponseModel } from './production-batch-planning-response.model';
import { PlanningModel } from '../../planning/model/planning.model';
import { StatusModel } from '../../pre-inward/modal/status-model';
import { OrgProfileModal } from '../../org-profile/modal/org-profile-modal';
import { BranchModel } from '../../branch/model/branch-model';
import { LocationMasterModel } from '../../location/model/location-master-model';
import { StockAreaModal } from '../../stock-area/modal/stock-area-modal';
import { DispatchDetailsModal } from '../../dispatch/modal/dispatch-details-modal';
import { PartnerModel } from '../../user/modal/partner-model';
import { FunItemProperties } from '../../funnel/funnel-product-add-update/funnel-product-add-update.component';

export class ProductionBatchModel {
  id: number;
  startDate= new Date();
  endDate= new Date();
  process: ProcessModel;
  processId: number;
  pbNumber: string;
  handingLoss: number;
  totalCost: number;
  totalWeight: number;
  outputInPer: number;
  party: PartyModal;
  partyId: number;
  salesOrder: SalesOrderModal;
  salesOrderId: number;
  machine: MachineModal;
  planning: PlanningModel;
  isCustomerWiseSo: boolean = false;
  productionBatchDetailsCostHead: Array<ProductionBatchDetailsCostHeadModel>;
  productionBatchItemDetailsInputs: Array<ProductionBatchDetailsInputModel>;
  productionBatchItemDetailsInputsOptimized: Array<ProductionBatchOptimisedModal>;
  productionBatchItemDetailsOutputs: Array<ProductionBatchDetailsOutputModel>;
  productionBatchItemDetailsOtherItems: Array<ProductionBatchDetailsOtherProduct>;
  moduleCustomFieldValues: Array<DynamicDataModel>;
  effectivePrice: number;
  status: StatusModel;
  partner : PartnerModel;
  stockId: number;
  inputStockArea: StockAreaModal;
  outputStockArea: StockAreaModal;
  byProductStockArea: StockAreaModal;
  inputStockAreaId: number;
  outputStockAreaId: number;
  byProductStockAreaId: number;
  // CBL Change #Dk
  company: OrgProfileModal;
  branch: BranchModel;
  location: LocationMasterModel;

  //For Angular Use, DO NOT DELETE IT
  totalCostHead: number;
  totalInputWeight: number;
  totalInputSeecondaryWeight: number;
  totalInputPrice: number;
  totalOutputWeight: number;
  totalOutputSecondaryWeight: number;
  totalOtherProductWeight: number;
  totalOtherProductSecondaryWeight: number;
  totalOtherProductCost: number;

  sdString: string;
  edString: string;
  soString: string;
  partyString: string;
  handingLossString: string;
  outputInPerString: string;
  totalWeightString: string;
  totalCostString: string;
  processString: string;
}

export class ProductionBatchOptimisedModal {
  id: number;
  qty:any;
  stockId: number;
  companyId: number;
  companyName: string;
  branchId: number;
  branchName: string;
  locationId: number;
  locationName: string;
  itemId: number;
  itemName: string;
  productCode: string;
  hsnCode:string;
  price: number;
  packSize: any;
  isPackSizeBasedCalculation: boolean;
  uom: string;
  secondaryUom: string;
  conversionFactor: number;
  partyId: number;
  partyName: string;
  salesOrderId: number;
  salesOrderNo: number;
  customItemNumber: string;
  manufacturingBatchNumber: string;
  itemTupleId: number;
  packingStockId: number;
  stock: number;
  secondaryStock: number;
  partnerId: number;
  rackId: number;
  rackCode: string;
  stockAreaId: number;
  stockAreaName: string;
  grnNumber: string;
  shortDescription: string;
  longDescription: string;
  itemProperties: Array<FunItemProperties>;
  weight: number;
  tempweight:number;
  noOfPackages: number;
  isFinishProduct : boolean;
  isPackingItem: boolean;
  isPackedItemFlag: boolean;
  optimizedData: number;
  packedStock: number;
  finishedProductDetails: Array<ProductionBatchOptimisedModal>;
  gst: number;
  //angular use
  isSelected: boolean;
  availableWeight: any;
  availableQuantity: number;
  totalWeight: number;
  totalPrice: number;
  companyStockId: number;
  soQuantity: number;
  dispatchQuantity: number;
  packedQuantity: number;
  isCompanyStock = false;
  dispatchedQuantity: number;
  totalCost: number;
  secondaryQuantity: number;
  ///For Angular Use
  discount: number;
  balance: number;
  quantity: number;
  orderedQty: number;
  discountAmount:number = 0;
  discountedAmount:number = 0;
  sgst: number;
  sgstAmount:number;
  cgst: number;
  cgstAmount:number;
  igst: number;
  igstAmount:number;
  finalAmt: number;
}
