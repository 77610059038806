import {Puritymodal} from "../../modules/purity/modal/puritymodal";
import {MoistureModal} from "../../modules/moisture/modal/moisture-modal";
import {PHModal} from "../../modules/ph/modal/phmodal";
import {SolubilityModal} from "../../modules/solubility/modal/solubility-modal";
import {StockParamModal} from "../../modules/stock-param/stock-param4/modal/stock-param-modal";
import {StockParam5Modal} from "../../modules/stock-param/stock-param5/modal/stock-param5-modal";
import {StockParam6Modal} from "../../modules/stock-param/stock-param6/modal/stock-param6-modal";
import {StockParam7Modal} from "../../modules/stock-param/stock-param7/modal/stock-param7-modal";
import {StockParam9Modal} from "../../modules/stock-param/stock-param9/modal/stock-param9-modal";

export class MaterialPropertyModel {
    purity: Puritymodal;
    moisture: MoistureModal;
    ph: PHModal;
    solubility: SolubilityModal;
    stockParam4: StockParamModal;
    stockParam5: StockParam5Modal;
    stockParam6: StockParam6Modal;
    stockParam7: StockParam7Modal;
    stockParam8: StockParam9Modal;
    stockParam9: StockParam9Modal;
}
