import { MachineModal } from '../../machine/modal/machine-modal';
import { PlanningDetailsModel } from './planning-details.model';
import { SalesOrderModal } from '../../sales-order/modal/sales-order-modal';
import { PlanningDetailsCostHeadModel } from './planning-details-cost-head.model';


export class PlanningModel {
    id: number;
    planDate: Date;
    planName: string;
    machine: MachineModal;
    planType: {
        id: number;
        planName: string;
    };
    status: {
        id: number;
        statusName: string;
    };
    so: SalesOrderModal;
    planningDetails: Array<PlanningDetailsModel>;
    costHeadDetails: Array<PlanningDetailsCostHeadModel>;
    totalCostHead: number;
    isDeleted: boolean;


    // For angular use
    totalWeight: number;
    totalAmount: number;
    dateString: string;
    machineString: string;
    planTypeString: string;
    soString: string;
    statusString: string;
}
