import { ModuleParamGlobConfigService } from '../../../core/service/moduleFieldConfig/module-param-glob-config.service';

export class MouldModuleParamConfigModel {
  constructor(private moduleParamConfigGlob: ModuleParamGlobConfigService) {}

  paramData = {
    title: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'title', 'Mould'),
    },
    subtitle: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'subtitle', 'Mould Details'),
    },
    basicDetail: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'basicDetail', 'Basic Details'),
    },
    mould_name: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'mould_name', 'Mould Name'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'mould_name'),
      isRequired: this.moduleParamConfigGlob.isRequired('mould', 'mould_name'),
    },
    mould_type: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'mould_type', 'Mould Type'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'mould_type'),
      isRequired: this.moduleParamConfigGlob.isRequired('mould', 'mould_type'),
    },
    mould_code: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'mould_code', 'Mould Code'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'mould_code'),
      isRequired: this.moduleParamConfigGlob.isRequired('mould', 'mould_code'),
    },
    status: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'status', 'Mould Status'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'status'),
    },
    cavity: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'cavity', 'Cavity'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'cavity'),
    },
    weight: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'weight', 'Unit Weight'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'weight'),
    },
    uom: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'uom', 'UOM'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'uom'),
    },
    runner_riser_weight: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'runner_riser_weight', 'Runner Riser Weight'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'runner_riser_weight'),
    },
    runner_riser_weight_uom: {
      label: this.moduleParamConfigGlob.getLabel('mould', 'runner_riser_weight_uom', 'Runner Riser Weight UOM'),
      isVisible: this.moduleParamConfigGlob.isVisible('mould', 'runner_riser_weight_uom'),
    },
  };
}
