import {SystemConfigService} from "../../../system-config/service/system-config.service";

export class DispatchSysConfigModel {
    isPkgTypeSupportOn: boolean;
    isSecondaryStockSupport: boolean;
    isRollNoStockSupport: boolean;
    vendorCodeShow: boolean;
    isPartLedger: boolean;

    constructor(public sysConfig: SystemConfigService) {
        this.sysConfiguration();
    }

    sysConfiguration() {
        this.isPkgTypeSupportOn = this.configurationCheck('PACKING_TYPE_SUPPORT');
        this.isSecondaryStockSupport = this.configurationCheck('SECONDARY_STOCK_SUPPORT');
        this.isRollNoStockSupport = this.configurationCheck('ROLL_NO_SUPPORT');
        this.vendorCodeShow = this.configurationCheck('VENDOR_CODE_ON');
        this.isPartLedger = this.configurationCheck('PARTY_ORDER_LEDGER_SUPPORT');
    }

    configurationCheck(param: string) {
        return this.sysConfig.sysConfig ? this.sysConfig.sysConfig.get(param) === 'YES' : false;
    }
}
